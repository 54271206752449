import { Alert, Button, Form, Input, Typography } from 'antd';
import EmptyLayout from '../components/layout/EmptyLayout';
import SearchEngineOptimization from '../components/utility/seo';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
// import { URLSearchParams } from 'url';

const { Text, Title } = Typography;

const CheckoutSuccess = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const orderId = searchParams.get('orderId');

  return (
    <EmptyLayout>
      <SearchEngineOptimization title="checkout-success" />
      <div className="flex flex-col items-center">
        <StaticImage
          src="../images/circle-check.png"
          alt="circle-check"
          placeholder="tracedSVG"
          className="w-[40px] h-[40px]"
          objectFit="contain"
        />
        <Title className="!font-semibold mt-5 mb-4 !text-light-title" level={3}>
          ขอบคุณที่สั่งซื้อกับเรา
        </Title>
        <Text className="!text-light-primary text-center">
          ระบบได้รับคำสั่งซื้อของคุณแล้ว ทางทีมงานจะเริ่มดำเนินการ
          สู่ขั้นตอนต่อไป คุณสามารถตรวจสอบสถานะของคำสั่งซื้อได้ผ่าน
          หน้าคำสั่งซื้อของคุณ
        </Text>
        <Link to={`/orders/${orderId}`}>
          <Button type="primary" className="mt-6">
            ดูรายละเอียดคำสั่งซื้อ
          </Button>
        </Link>
        <Link to="/products" className="mt-4 !font-semibold">
          ดูสินค้าอื่นเพิ่มเติม
        </Link>
      </div>
    </EmptyLayout>
  );
};

export default CheckoutSuccess;
